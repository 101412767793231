import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import { useLandingContext } from "../providers/LandingProvider";
import { DEFAULT_COLOR } from "../utils/constants";
import Footer from "../components/Footer/Footer";
import FiraTag from "../components/FiraTag/FiraTag";
import { getContrastColor } from "../utils/getContrastColor";

const HomeLayout = () => {
	const { configuration, contrastColor } = useLandingContext();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 982);
	const { slug } = useParams();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 982);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (!configuration) {
		return <div>LOADING</div>;
	}

	const { firaLandingConfiguration } = configuration;

	return (
		<div>
			<FiraTag />
			<Header
				slug={slug}
				isMobile={!isMobile}
				color={
					firaLandingConfiguration.headerColor
						? firaLandingConfiguration.headerColor
						: DEFAULT_COLOR
				}
				logoURL={
					isMobile
						? firaLandingConfiguration.mobileLogoUrl
						: firaLandingConfiguration.webLogoUrl
				}
				alignLogo={firaLandingConfiguration.logoAlignment}
				headerHeight={firaLandingConfiguration.headerHeight}
				contrastColor={getContrastColor(
					firaLandingConfiguration.headerColor
						? firaLandingConfiguration.headerColor
						: DEFAULT_COLOR
				)}
			/>
			<Outlet />
			<Footer
				color={
					firaLandingConfiguration.footerColor
						? firaLandingConfiguration.footerColor
						: DEFAULT_COLOR
				}
				logoURL={
					isMobile
						? firaLandingConfiguration.mobileLogoUrl
						: firaLandingConfiguration.webLogoUrl
				}
				contrastColor={contrastColor}
				fontFooter={
					firaLandingConfiguration.fontFooter
						? firaLandingConfiguration.fontFooter
						: ""
				}
				fontSizeFooter={
					firaLandingConfiguration.fontSizeFooter
						? Number(firaLandingConfiguration.fontSizeFooter)
						: 0
				}
			/>
		</div>
	);
};

export default HomeLayout;
