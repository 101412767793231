import React, { useEffect, useState } from "react";

import { useLandingContext } from "../../providers/LandingProvider";
import PlayerWrapper from "../PlayerWrapper/PlayerWrapper";
import WidgetWrapper from "../WidgetWrapper/WidgetWrapper";
import stylesheet from "./Main.module.scss";
import { getLastEvent } from "../../services";
import BannerLive from "../BannerLive/BannerLive";
import { useCart } from "../../providers/CartContext";
import FeaturedProductsWidget from "../FeaturedProductsWidget/FeaturedProductsWidget";

const { MainWrapper, ContentWrapper } = stylesheet;

export const Main: React.FC = () => {
	const { configuration, currentVideo, setCurrentVideo } = useLandingContext();

	const [isMobile, setIsMobile] = useState(window.innerWidth < 982);
	const [eventName, setEventName] = useState("");
	const [eventLive, setEventLive] = useState("");
	const [isLiveActive, setIsLiveActive] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 982);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const startPlayer = async () => {
			if (configuration && configuration.storeId) {
				try {
					const response = await getLastEvent(configuration.storeId);
					if (response.data.lastEventResponseDto) {
						setCurrentVideo(response.data.lastEventResponseDto.id);
						if (response.data.lastEventResponseDto.status === "STARTED") {
							setEventLive(response.data.lastEventResponseDto.id);
							setEventName(response.data.lastEventResponseDto.name);
							setIsLiveActive(true);
						}
					} else if (response.data.lastVODAvailableResponseDto) {
						setCurrentVideo(
							response.data.lastVODAvailableResponseDto.originEvent
						);
					}
				} catch (error) {
					console.error("getting last event", error);
				}
			}
		};
		if (configuration?.storeId) {
			startPlayer();
			console.log(configuration);
		}
		console.log("entra config");
	}, [configuration]);

	useEffect(() => {
		if (
			configuration?.firaLandingConfiguration.fontContent ||
			configuration?.firaLandingConfiguration.fontSizeContent ||
			configuration?.firaLandingConfiguration.fontWeightContent
		) {
			const style = document.createElement("style");
			let styleContent = `
      @import url('https://fonts.googleapis.com/css?family=${encodeURIComponent(
				configuration.firaLandingConfiguration.fontContent
					? configuration.firaLandingConfiguration.fontContent
					: ""
			)}');
      .mainContent {
        font-family: '${
					configuration.firaLandingConfiguration.fontContent
				}', sans-serif;
        font-weight: ${
					configuration.firaLandingConfiguration.fontWeightContent
				};
      `;

			if (
				configuration.firaLandingConfiguration.fontSizeContent &&
				configuration.firaLandingConfiguration.fontSizeContent > 0
			) {
				styleContent += `font-size: ${configuration.firaLandingConfiguration.fontSizeContent}px;`;
			}

			styleContent += `}`;

			style.appendChild(document.createTextNode(styleContent));
			document.head.appendChild(style);
		}
	}, [
		configuration?.firaLandingConfiguration.fontContent,
		configuration?.firaLandingConfiguration.fontSizeContent,
		configuration?.firaLandingConfiguration.fontWeightContent,
	]);

	if (!configuration) {
		return <div>LOADING</div>;
	}

	const { firaLandingConfiguration, storeId } = configuration;
	const { widgetContent } = firaLandingConfiguration;

	const handleClick = () => {
		setCurrentVideo(eventLive);
	};

	return (
		<div className={`${MainWrapper} ${"mainContent"}`}>
			{isLiveActive && (
				<BannerLive eventName={eventName} onClick={handleClick} />
			)}

			<div
				className={ContentWrapper}
				style={{ background: firaLandingConfiguration.bgColor }}
			>
				<PlayerWrapper
					firaKey={storeId ? storeId : ""}
					firaSrc={currentVideo}
					shoppingCart={configuration.shoppingCart}
					phoneNumber={configuration.storeNumber}
				/>
				{configuration.firaLandingConfiguration.widgetContent.widgetType ===
				"VOD" ? (
					<WidgetWrapper
						firaKey={storeId ? storeId : ""}
						widgetConfig={widgetContent}
					/>
				) : (
					<FeaturedProductsWidget
						widgetConfig={widgetContent}
						bulletActiveColor={"#000"}
					/>
				)}
			</div>
		</div>
	);
};
