import axios from "axios";
import { GetLandingResponse, GetLastEventResponse } from "../types";

const apiBusiness = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

const apiManagement = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

export const getLandingConfig = (name: string) => {
	return apiBusiness.get<GetLandingResponse>(
		`/v1/fira-landing/by-name/${name}`
	);
};

export const getLastEvent = (firaKey: string) => {
	return apiManagement.get<GetLastEventResponse>(
		`/api/v1/fbe-vod-controller/widget/${firaKey}`
	);
};

export const getLandingsProductsByStoreIdService = (storeId: string) => {
	return apiManagement.get(
		`/api/v1/fira-product/pro-seller/store-id/${storeId}`
	);
};

// /api/v1/fira-product/landing-products/{storeId}/{offSet}/{size}/{order}
export const getLandingsProductsCatalogService = (
	storeId: string,
	offSet: number,
	size: number,
	order: string,
	name?: string
) => {
	let body = { name: name };
	return apiManagement.get(
		`/api/v1/fira-product/landing-products/${storeId}/${offSet}/${size}/${order}`,
		{ params: body }
	);
};
