import * as React from "react";
import { SVGProps } from "react";

const Shopping = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={22}
		height={23}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				d="m3 5.18 3-4h12l3 4m-18 0v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-14m-18 0h18m-5 4a4 4 0 1 1-8 0"
				stroke="currentColor"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(0 .82)" d="M0 0h26v26H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default Shopping;
