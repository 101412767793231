import stylesheet from "./FeaturedProductsWidget.module.scss";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import { FiraWidgetConfiguration, LandingProduct, ProductInfo } from "../../types";
import { useLandingContext } from "../../providers/LandingProvider";
import { CartProduct, useCart } from "../../providers/CartContext";
import currencyFormat from "../../utils/currencyFormat";
import "./SwiperStyles.scss";
import { getLandingsProductsByStoreIdService } from "../../services";
const {
	FeaturedProductsWidgetWrapper,
	FeaturedProductItem,
	ProductsList,
	Image,
	Title,
	Price,
	Buttons,
	QtyButton,
	AddButton,
	qtyNumber,
	mySwiper,
	mySlide,
	Pressed,
} = stylesheet;

interface Props {
	widgetConfig: FiraWidgetConfiguration;
	bulletActiveColor: string;
}

const FeaturedProductsWidget: React.FC<Props> = ({
	widgetConfig,
	bulletActiveColor,
}: Props) => {
	const { configuration } = useLandingContext();
	const [productWidget, setProductWidget] = useState<LandingProduct[]>([]);

	useEffect(() => {
		configuration?.storeId !== "" &&
			configuration?.storeId !== undefined &&
			setProductWidget(configuration?.featuredProducts);
	}, [configuration?.storeId]);

	const getSlidesQty = () => {
		if (productWidget) {
			if (productWidget.length >= 4) {
				return 4;
			} else {
				return productWidget.length;
			}
		}
	};

	return (
		<div className={FeaturedProductsWidgetWrapper}>
			<h1>{widgetConfig.sectionTitle || "Título de categoría"}</h1>
			<div className={ProductsList}>
				<Swiper
					style={{
						"--swiper-pagination-bullet-active": "#000",
						"--swiper-pagination-color": bulletActiveColor,
					}}
					loop={false}
					autoplay={true}
					slidesPerView={getSlidesQty()}
					slidesPerGroupSkip={0}
					slidesPerGroup={getSlidesQty()}
					breakpoints={{
						100: {
							slidesPerView: 1,
							slidesPerGroup: 1,
						},
						720: {
							slidesPerView: getSlidesQty(),
							slidesPerGroup: getSlidesQty(),
						},
						980: {
							slidesPerView: getSlidesQty(), //getSlidesQty(),
							slidesPerGroup: getSlidesQty(), //getSlidesQty(),
						},
					}}
					pagination={{
						clickable: true,
						type: "bullets",
					}}
					modules={[Pagination]}
					className={mySwiper}
				>
					{productWidget.map((slideContent, index) => (
						<SwiperSlide virtualIndex={index} className={mySlide} key={index}>
							<FeaturedProduct product={slideContent} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

interface FeaturedProductProps {
	product: LandingProduct;
}

export const FeaturedProduct = ({ product }: FeaturedProductProps) => {
	const [qty, setQty] = useState<number>(1);
	const [CurrentProduct, setCurrentProduct] = useState<ProductInfo>({
		id: product.id,
		directUrl: product.directUrl,
		images: product.images,
		name: product.name,
		price1: Number(product.price1),
		price2: Number(product.price2),
		price3: Number(product.price3),
		quantity: 0,
	});
	const { addProductToCart } = useCart();
	const { configuration, showHeaderBriefly } = useLandingContext();
	const [isButtonPressed, setIsButtonPressed] = useState(false);

	useEffect(() => {
		setCurrentProduct({
			id: product.id,
			directUrl: product.directUrl,
			images: product.images,
			name: product.name,
			price1: Number(product.price1),
			price2: Number(product.price2),
			price3: Number(product.price3),
			quantity: 1,
		});
	}, [product]);

	const addToGlobalCart = () => {
		setIsButtonPressed(true);
		showHeaderBriefly();
		const { name, images, price1, price2, id } = CurrentProduct
		const productToCart: CartProduct = {
			name,
			image: images && images.length > 0 ? images[0] : '',
			price1: `${price1}`,
			price2: `${price2}`,
			sku: id,
			quantity: qty,
		};

		addProductToCart(productToCart);
		setTimeout(() => setIsButtonPressed(false), 1000);
	};
	const handleClick = () => {
		const enlace = CurrentProduct.directUrl;

		window.open(enlace, "_blank");
	};

	const add = () => {
		setCurrentProduct(prevProduct => ({
			...prevProduct,
			quantity: qty + 1,
		}));
		setQty(qty + 1);
	};
	const subtract = () => {
		if (qty === 1) {
			return;
		} else {
			setCurrentProduct(prevProduct => ({
				...prevProduct,
				quantity: qty - 1,
			}));
			setQty(qty - 1);
		}
	};

	const hasDiscount =
		Number(product.price2) < Number(product.price1) &&
		Number(product.price2) > 0;

	return (
		<div className={FeaturedProductItem}>
			<div className={Image}>
				<img src={product.images[0]} alt="" />
			</div>
			<div className={Title}>{product.name}</div>
			<div
				style={{
					gap: 2,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div
					className={Price}
					style={{
						textDecorationLine: hasDiscount ? "line-through" : "none",
						fontWeight: hasDiscount ? 300 : 600,
					}}
				>
					{String(
						currencyFormat(
							Number(product.price1),
							configuration?.storeCurrency ? configuration?.storeCurrency : ""
						)
					)}
				</div>
				{hasDiscount && (
					<div className={Price}>
						{String(
							currencyFormat(
								Number(product.price2),
								configuration?.storeCurrency ? configuration?.storeCurrency : ""
							)
						)}
					</div>
				)}
			</div>

			<div className={Buttons}>
				{configuration?.shoppingCart && (
					<div className={QtyButton}>
						<button onClick={subtract}>-</button>
						<div className={qtyNumber}>{qty}</div>
						<button onClick={add}>+</button>
					</div>
				)}
				<button
					className={`${AddButton} ${isButtonPressed ? Pressed : ""}`}
					onClick={configuration?.shoppingCart ? addToGlobalCart : handleClick}
				>
					{configuration?.shoppingCart
						? isButtonPressed
							? "Agregado!"
							: "Agregar"
						: "Comprar"}
				</button>
			</div>
		</div>
	);
};

export default FeaturedProductsWidget;
