export interface GetLandingResponse {
	id: string;
	name: string;
	storeId?: string;
	storeCurrency?: string;
	firaLandingConfiguration: FiraLandingConfiguration;
	active: boolean;
	storeNumber: Phone;
	phoneNumber: Phone;
	shoppingCart: boolean;
	storeName: string;
	storeCurrency: string;
	featuredProducts: LandingProduct[];
	products: LandingProduct[];
}

interface FiraLandingConfiguration {
	storeUrl: string;
	webLogoUrl: string;
	mobileLogoUrl: string;
	headerHeight: number;
	headerColor: string;
	footerColor: string;
	bgColor: string;
	logoAlignment: LogoAlignment;
	mobileCentered: boolean;
	fontHeader?: string;
	fontSizeHeader?: number;
	fontContent?: string;
	fontSizeContent?: number;
	fontWeightContent?: number;
	fontFooter?: string;
	fontSizeFooter?: number;
	fontWeightFooter?: number;
	widgetContent: FiraWidgetConfiguration;
}

interface FiraWidgetConfiguration {
	widgetId: string;
	previousEvents: string;
	collectionId: string;
	sectionTitle: string;
	bgColor: string;
	fontColor: string;
	displayColor: string;
	detailsColor: string;
	widgetType?: WidgetType;
}

export interface GetLastEventResponse {
	lastEventResponseDto?: {
		id: string;
		name: string;
		teaser?: string;
		eventDescription: string;
		previewImage?: string;
		categories: [
			{
				id: string;
				description: string;
			},
		];
		status: string;
	};
	lastVODAvailableResponseDto?: {
		id: string;
		storeId: string;
		originEvent: string;
		videoName: string;
		eventName: string;
		videoDescription: string;
		creationDate: string;
		categories: [
			{
				id: string;
				description: string;
			},
		];
		videoUrls: [
			{
				url: string;
				videoFormat: "MP4";
				videoSize: 0;
				videoDuration: 0;
				videoDurationState: "COMPLETE";
				state: "CORRUPTED";
			},
		];
		eventTeaser: string;
		eventPreviewImage: string;
		visibility: "HIDE";
		status: "PROCESSING";
		videoEditing: "ENABLED";
		attributeEditing: "ENABLED";
		commentSection: "ENABLED";
		videoClassification: "TV_Y";
		productPriceAllowed: {
			price1: "ENABLED";
			price2: "ENABLED";
			price3: "ENABLED";
		};
		visibleFrom: string;
	};
}

export interface ProductInfo {
	name: string;
	directUrl: string;
	images: string[];
	id: string;
	price1: number;
	price2: number;
	price3: number;
	quantity: number;
}
export enum WidgetType {
	vod = "VOD",
	products = "PRODUCTS",
}

interface LandingProduct {
	id: string;
	externalId: string;
	storeId: string;
	sku: string;
	name: string;
	description: string;
	price1: string;
	price2: string;
	price3: string;
	quantity: number;
	directUrl: string;
	createAt: string;
	modifiedAt: string;
	images: string[];
	status: ProductStatus;
	selected?: boolean;
	inLanding?: boolean;
	featuredProduct?: boolean;
}

//price1_desc, price1_asc, name_desc, name_asc
export enum ProductFilter {
	descend = "name_desc",
	ascend = "name_asc",
	lowestPrice = "price1_asc",
	highestPrice = "price1_desc",
}

export enum Currency {
	usd = "USD",
	eur = "EUR",
	pen = "PEN",
	brl = "BRL",
	mxn = "MXN",
	cop = "COP",
	clp = "CLP",
	ars = "ARS",
	gtq = "GTQ",
	nio = "NIO",
	crc = "CRC",
	hnl = "HNL",
	svc = "SVC",
}

export interface Phone {
	country: string;
  operator: string;
  number: string;
  type: string;
}
