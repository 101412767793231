import React from "react";
import { FiraLogo } from "../../assets/FiraLogo";

import stylesheet from "./FiraTag.module.scss";

const { FiraTagWrapper, Logo, FIRA } = stylesheet;

const FiraTag: React.FC = () => {
	return (
		<div className={FiraTagWrapper}>
			<a href="https://firaonlive.com" target="_blank">
				powered by <span className={FIRA}>FIRA</span>
			</a>
			<FiraLogo className={Logo} />
		</div>
	);
};

export default FiraTag;
