import React, { ChangeEvent, useEffect, useState } from "react";
import {
	LandingMode,
	useLandingContext,
} from "../../providers/LandingProvider";
import { FeaturedProduct } from "../FeaturedProductsWidget/FeaturedProductsWidget";
import stylesheet from "./Catalogo.module.scss";
import Input from "../Input/Input";
import MagnifyingGlass from "../FiraIcons/MagnifyingGlass";
import ArrowDownSimple from "../FiraIcons/ArrowDownSimple";
import Filter from "../FiraIcons/Filter";
import SvgCloseButtonX from "../FiraIcons/CloseButtonX";
import { useClickOutside } from "../../utils/useClickOutside";
import { getLandingsProductsCatalogService } from "../../services";
import { LandingProduct, ProductFilter } from "../../types/index.d";
import { getContrastColor } from "../../utils/getContrastColor";
import { DEFAULT_COLOR } from "../../utils/constants";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import SvgArrowLeft from "../FiraIcons/ArrowLeft";
import SvgArrowRight from "../FiraIcons/ArrowRight";
const {
	catalogoContainer,
	gridContainer,
	WrapperPaginacion,
	paginacion,
	paginaActiva,
	filterBar,
	titleFilter,
	orderingContainer,
	orderingTitle,
	orderingOptions,
	catalogoMain,
	mobileFilterButton,
	mobileMenu,
	closeMobileMenu,
	slideInLeft,
	opacityOverlay,
} = stylesheet;

const Catalogo: React.FC = () => {
	const { configuration, mode } = useLandingContext();
	const productsByPage: number = 16;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const domNode = useClickOutside(() => setOpenList(false));
	const [nameFilter, setFiltroNombre] = useState<string>("");
	const [precioMin, setPrecioMin] = useState<string>("");
	const [precioMax, setPrecioMax] = useState<string>("");
	const [order, setOrder] = useState<string>(ProductFilter.ascend);
	const [openList, setOpenList] = useState(false);
	const [openOptionsPrice, setOpenOptionPrice] = useState(false);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [products, setProducts] = useState<LandingProduct[]>([]);
	const [isMobile, setIsMobile] = useState(false);

	const [totalPages, setTotalPages] = useState<number>(1);

	const orderingList = [
		/*{
			id: 1,
			name: "Precio más alto",
			value: ProductFilter.highestPrice,
		},
		{
			id: 2,
			name: "Precio más bajo",
			value: ProductFilter.lowestPrice,
		},*/
		{
			id: 3,
			name: "Alfabéticamente A - Z",
			value: ProductFilter.ascend,
		},
		{
			id: 4,
			name: "Alfabéticamente Z - A",
			value: ProductFilter.descend,
		},
	];

	const searchProductsByName = async (name: string) => {
		try {
			const response = await getLandingsProductsCatalogService(
				configuration?.storeId ? configuration?.storeId : "",
				0,
				productsByPage,
				order,
				name
			);

			setTotalProducts(response.data.totalProductos || 0);
			setTotalPages(response.data.totalPages);
			setProducts(response.data.content || []);

			return response.data.content || [];
		} catch (error) {
			console.error("Error fetching landing products", error);
			return [];
		}
	};

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setFiltroNombre(value);
		setCurrentPage(1);
		searchProductsByName(value);
	};

	const onChangeMin = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (/^\d*\.?\d{0,2}$/.test(value) || value === "") {
			setPrecioMin(value);
		}
	};

	const onChangeMax = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setPrecioMax(value);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 860);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const getLandingProducts = async (
		storeId: string,
		offSet: number,
		size: number,
		order: string
	): Promise<LandingProduct[]> => {
		try {
			const response = await getLandingsProductsCatalogService(
				storeId,
				offSet,
				size,
				order
			);

			setTotalProducts(response.data.totalProductos || 0);
			setTotalPages(response.data.totalPages);
			setProducts(response.data.content || []);

			return response.data.content || [];
		} catch (error) {
			console.error("Error fetching landing products", error);
			return [];
		}
	};

	useEffect(() => {
		if (configuration?.storeId && mode === LandingMode.NORMAL) {
			getLandingProducts(configuration?.storeId, 0, productsByPage, order);
		} else {
			setTotalProducts(0);
			setTotalPages(1);
			setProducts([]);
		}
	}, [configuration?.storeId, order]);

	const onOptionClick = (event: React.MouseEvent) => {
		// Evitar que el clic se propague al contenedor principal
		event.stopPropagation();
	};
	const [optionName, setOptionName] = useState("Alfabéticamente A-Z");

	const setSelectedOption = (value: string, name: string) => {
		setOptionName(name);
		setOrder(value);
	};

	const containerStyle = {
		backgroundColor: openList
			? configuration?.firaLandingConfiguration.headerColor
			: "white",
		color: openList
			? getContrastColor(
					configuration?.firaLandingConfiguration.headerColor
						? configuration?.firaLandingConfiguration.headerColor
						: DEFAULT_COLOR
			  )
			: "#000",
	};
	const handlePageClick = async (event: any) => {
		const numeroPagina = event.selected + 1;
		setCurrentPage(numeroPagina);
		const offSet = numeroPagina - 1;
		try {
			const response = await getLandingsProductsCatalogService(
				configuration?.storeId ? configuration?.storeId : "",
				offSet,
				productsByPage,
				order
			);

			setProducts(response.data.content);
		} catch (error) {
			console.error("Error fetching landing products", error);
		}
	};
	return (
		<div className={catalogoMain}>
			<div className={catalogoContainer}>
				{mobileMenuOpen && (
					<div className={`${mobileMenu} ${mobileMenuOpen ? slideInLeft : ""}`}>
						<div
							className={closeMobileMenu}
							onClick={() => setMobileMenuOpen(false)}
						>
							<div style={{ display: "flex", gap: "16px" }}>
								<Filter />
								<label className={titleFilter}>FILTRAR</label>
							</div>

							<SvgCloseButtonX />
						</div>
						<CustomInput
							height="44px"
							width="100%"
							onChange={onChange}
							value={nameFilter}
							icon={
								<MagnifyingGlass
									className="SearchIcon"
									width={30}
									height={30}
									color="#ABAFB1"
								/>
							}
							placeholder={"Toca para buscar..."}
						/>
						{/*<div
							className={orderingPriceContainer}
							onClick={() => setOpenOptionPrice(!openOptionsPrice)}
							ref={domNode}
						>
							<div className={orderingPriceTitle}>
								<p className="body-14-text">{"Precios"}</p>
								<ArrowDownSimple />
							</div>

							<div className={priceOptions} onClick={onOptionClick}>
								<div className={itemOption}>
									<label>Precio mínimo</label>

									<Input
										height="45px"
										width="100%"
										onChange={onChangeMin}
										value={precioMin}
										placeholder={""}
										currency={getCurrencySymbol(
											configuration?.storeCurrency
												? configuration?.storeCurrency
												: ""
										)}
									/>
								</div>

								<div className={itemOption}>
									<label>Precio máximo</label>
									<Input
										height="45px"
										width="100%"
										onChange={onChangeMax}
										value={precioMax}
										placeholder={""}
										currency={getCurrencySymbol(
											configuration?.storeCurrency
												? configuration?.storeCurrency
												: ""
										)}
									/>
								</div>
							</div>
										</div>*/}
					</div>
				)}
				{mobileMenuOpen && <div className={opacityOverlay}></div>}
				<div className={filterBar}>
					{!isMobile ? (
						<>
							<Input
								height="44px"
								width="100%"
								onChange={onChange}
								value={nameFilter}
								icon={<MagnifyingGlass className="SearchIcon" />}
								placeholder={"Toca para buscar..."}
							/>

							<label
								htmlFor="orden"
								style={{
									width: "225px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								ORDENAR POR
							</label>
						</>
					) : (
						<div
							className={mobileFilterButton}
							onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
						>
							<Filter /> FILTRAR
						</div>
					)}

					<div
						className={orderingContainer}
						onClick={() => setOpenList(!openList)}
						ref={domNode}
						style={containerStyle}
					>
						<div className={orderingTitle}>
							<p className="body-14-text">{optionName}</p>
							<ArrowDownSimple />
						</div>

						{openList && (
							<div className={orderingOptions}>
								{orderingList?.map(option => (
									<p
										onClick={() => setSelectedOption(option.value, option.name)}
										key={option.name}
										className="body-14-text insideOption"
									>
										{option.name}
									</p>
								))}
							</div>
						)}
					</div>
				</div>

				<div className={gridContainer}>
					{products.map((slideContent, index) => (
						<FeaturedProduct key={index} product={slideContent} />
					))}
				</div>

				<div className={WrapperPaginacion}>
					<div className={paginacion}>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<SvgArrowRight width={20} height={20} />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={1}
							pageCount={totalPages}
							previousLabel={<SvgArrowLeft width={20} height={20} />}
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

interface CustomInputProps {
	height: string;
	width: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value: string;
	icon?: React.ReactNode;
	placeholder: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
	height,
	width,
	onChange,
	value,
	icon,
	placeholder,
}) => {
	const inputStyles: React.CSSProperties = {
		height,
		width,
		borderBottom: "1px solid #C7C7C7",
		borderLeft: "none",
		borderRight: "none",
		borderTop: "none",
		outline: "none",
		padding: "8px",
		boxSizing: "border-box",
		background: "transparent",
		paddingLeft: "42px",
	};

	return (
		<div style={{ position: "relative", width: "100%" }}>
			{icon && (
				<div
					style={{
						position: "absolute",
						left: "8px",
						top: "50%",
						transform: "translateY(-50%)",
					}}
				>
					{icon}
				</div>
			)}
			<input
				type="text"
				style={inputStyles}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default Catalogo;
