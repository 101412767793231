import * as React from "react";
import { SVGProps } from "react";

const Filter = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={16}
		height={19}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.001 9.498v7.88c.04.3-.06.62-.29.83a.997.997 0 0 1-1.41 0l-2.01-2.01a.989.989 0 0 1-.29-.83v-5.87h-.03l-5.76-7.38a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4l-5.76 7.38h-.03Z"
			fill="#000"
		/>
	</svg>
);

export default Filter;
