import React, { useEffect } from "react";

import stylesheet from "./WidgetWrapper.module.scss";
import { FiraWidgetConfiguration } from "../../types";
import { useLandingContext } from "../../providers/LandingProvider";

const { WidgetContainer } = stylesheet;

interface Props {
	firaKey: string;
	widgetConfig: FiraWidgetConfiguration;
}
const WidgetWrapper: React.FC<Props> = ({ firaKey, widgetConfig }) => {
	const { setCurrentVideo } = useLandingContext();

	// @ts-ignore
	const clickOnEventHandler = ev => {
		const eventId = ev.target.getAttribute("data-fira-function");
		if (eventId) setCurrentVideo(eventId);
	};

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			"https://firalivepro.blob.core.windows.net/fira-live-widget/carrousel-collections-carrousel/index.js";
		script.async = true;
		document.body.appendChild(script);

		document.addEventListener("click", clickOnEventHandler);

		return () => {
			document.body.removeChild(script);
			document.removeEventListener("click", clickOnEventHandler);
		};
	}, []);

	const environment = process.env.REACT_APP_CURRENT_ENVIRONMENT;

	return (
		<div className={WidgetContainer}>
			<link
				href="https://firalivepro.blob.core.windows.net/fira-live-widget/carrousel-collections-carrousel/index.css"
				rel="stylesheet"
			/>

			<div
				className="fira_events_carrousel_widget"
				fira-key={firaKey}
				fira-mode={"landing"}
				fira-primary-text-color={widgetConfig.fontColor}
				fira-diplay-color={widgetConfig.displayColor}
				fira-primary-color={widgetConfig.detailsColor}
				fira-background-color={widgetConfig.bgColor}
				fira-title={widgetConfig.sectionTitle}
				fira-events-mode={widgetConfig.previousEvents}
				fira-env={environment || "DEV"}
				fira-collection-id={widgetConfig.collectionId}
			></div>
		</div>
	);
};

export default WidgetWrapper;
