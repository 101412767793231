import stylesheet from "../FiraShoppingCart/FiraShoppingCart.module.scss";
import ShoppingForm from "./components/ShoppingForm/ShoppingForm";
import { useEffect, useState } from "react";
import noImage from "../../assets/noImage.svg";
import { CartProduct, useCart } from "../../providers/CartContext";
import Empty from "../FiraIcons/Empty";
import WhatsappFlat from "../FiraIcons/WhatsappFlat";
import { useLandingContext } from "../../providers/LandingProvider";
import Delete from "../FiraIcons/Delete";
import { getTextColor } from "../../utils/getContrastColor";
import currencyFormat from "../../utils/currencyFormat";
import Cart from "../FiraIcons/Cart";
import CloseButtonX from "../FiraIcons/CloseButtonX";

const {
	ShoppingCartWrapper,
	FiraCartItemWrapper,
	gridImage,
	gridInfo,
	gridActions,
	imageContainer,
	infoTitle,
	deleteIcon,
	price,
	inputWrapper,
	ItemDivider,
	Items,
	Bottom,
	Subtotal,
	subtotalPrice,
	emptyContainer,
	infoText,
	LandingCounter,
	InfoContainer,
	control,
	ItemCart,
	indicatorCart,
	WrapperWeb,
	WrapperMobile,
	MobileInfo,
} = stylesheet;

interface ShoppingCartProps {
	color: string;
	isMobile: boolean;
	onClose(): void;
}
const FiraShoppingCart: React.FC<ShoppingCartProps> = ({
	color,
	isMobile,
	onClose,
}) => {
	const [showForm, setShowForm] = useState<boolean>(false);
	const { cart, addProductToCart } = useCart();
	const { configuration } = useLandingContext();

	const calculateSumTotal = (products: CartProduct[]): number => {
		let sum = 0;
		for (let i = 0; i < products.length; i++) {
			const item = products[i];
			const price =
				item.price2 && parseFloat(item.price2) > 0
					? parseFloat(item.price2)
					: parseFloat(item.price1);
			sum += price * item.quantity;
		}
		return sum;
	};

	const calculateItemTotal = (products: CartProduct[]) => {
		return products.reduce((sum, item) => {
			const totalQuantity = +item.quantity;
			return sum + totalQuantity;
		}, 0);
	};

	return (
		<>
			<div
				className={`${ShoppingCartWrapper} ${
					isMobile ? WrapperWeb : WrapperMobile
				}`}
			>
				<div className={ItemCart}>
					<Cart width={30} height={30} />
					{"  Mi carrito  (" + calculateItemTotal(cart) + ")"}
					<CloseButtonX
						color={"#000"}
						style={{ cursor: "pointer", right: "20px", position: "absolute" }}
						onClick={onClose}
					/>
				</div>
				<div style={{ backgroundColor: color }} className={indicatorCart}></div>
				{cart.length > 0 ? (
					<div className={Items}>
						{cart &&
							cart.map((product: CartProduct, i: number) => (
								<FiraCartItem product={product} isMobile={isMobile} />
							))}
					</div>
				) : (
					<div className={emptyContainer}>
						<Empty />
						<div className={infoText}>{"Todavía no tienes productos"}</div>
					</div>
				)}

				<div className={Bottom}>
					<div className={ItemDivider}></div>
					<div className={Subtotal}>
						<span>Subtotal:</span>{" "}
						<span className={subtotalPrice}>
							{String(
								currencyFormat(
									calculateSumTotal(cart),
									configuration?.storeCurrency
										? configuration?.storeCurrency
										: ""
								)
							)}
						</span>
					</div>
					<button
						onClick={() => setShowForm(true)}
						style={{
							backgroundColor: color,
							color: getTextColor(color),
						}}
					>
						<WhatsappFlat width={24} height={24} color={getTextColor(color)} />
						<span>{"Continuar compra"}</span>
					</button>
				</div>
			</div>
			{showForm && (
				<ShoppingForm
					onClose={() => setShowForm(false)}
					color={color}
					isMobile={isMobile}
				/>
			)}
		</>
	);
};

interface FiraCartItemProps {
	product: CartProduct;
	isMobile: boolean;
}

const FiraCartItem = ({ product, isMobile }: FiraCartItemProps) => {
	const { configuration } = useLandingContext();

	const { updateProductQuantity, removeProductFromCart } = useCart();
	const [qty, setQty] = useState<number>(product.quantity || 1);

	const updateQuantity = (newQty: number) => {
		setQty(newQty);
		updateProductQuantity(product.sku, newQty);
	};

	const deleteProduct = (id: string) => {
		removeProductFromCart(id);
	};

	useEffect(() => {
		setQty(product.quantity);
	}, [product]);

	return (
		<div className={FiraCartItemWrapper}>
			<div id={gridImage}>
				<div className={imageContainer}>
					<img
						src={product.image ? product.image : noImage}
						alt=""
					/>
				</div>
			</div>
			<div id={gridInfo}>
				<div className={infoTitle}>{product.name}</div>
				<div className={deleteIcon}>
					<Delete
						height={28}
						width={28}
						color={"#F16063"}
						onClick={() => deleteProduct(product.sku)}
					/>
				</div>
			</div>
			<div id={gridActions}>
				<div style={{ color: "#000" }} className={price}>
					{String(
						currencyFormat(
							product.price2 && parseFloat(product.price2) > 0
								? parseFloat(product.price2)
								: parseFloat(product.price1),
							configuration?.storeCurrency ? configuration?.storeCurrency : ""
						)
					)}
				</div>
				<div className={inputWrapper}>
					{product && (
						<div className={InfoContainer}>
							<div className={`${LandingCounter} ${!isMobile && MobileInfo}`}>
								<div
									className={control}
									onClick={() => updateQuantity(Math.max(qty - 1, 1))}
								>
									-
								</div>
								<div>{qty}</div>
								<div
									className={control}
									onClick={() => updateQuantity(qty + 1)}
								>
									+
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FiraShoppingCart;
