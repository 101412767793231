import React, { useEffect, useRef, useState } from "react";

import stylesheet from "./PlayerWrapper.module.scss";
import { getLastEvent } from "../../services";
import { Phone } from "../../types";

const { PlayerContainer, IframeWrapper } = stylesheet;

interface Props {
	firaKey: string;
	firaSrc?: string;
	shoppingCart: boolean;
	phoneNumber: Phone;
}
const PlayerWrapper: React.FC<Props> = ({
	firaKey,
	shoppingCart,
	phoneNumber,
	firaSrc,
}) => {
	const [playerURL, setPlayerURL] = useState<URL>();
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const reloadIframe = () => {
		if (iframeRef.current) {
			iframeRef.current.src += "";
		}
	};

	useEffect(() => {
		const startPlayer = async () => {
			try {
				const iframeURL = new URL(process.env.REACT_APP_BASE_URL_PLAYER || "");
				iframeURL.searchParams.append("firakey", firaKey);
				iframeURL.searchParams.append("firasrc", firaSrc || "");
				const shareLink =
					document.location && document.location.href
						? document.location.href.split("&").join("___")
						: "";
				iframeURL.searchParams.append("shareLink", shareLink);
				iframeURL.searchParams.append("shoppingCart", "" + shoppingCart);
				iframeURL.searchParams.append("phoneNumber", `${phoneNumber.operator}${phoneNumber.number}`);
				setPlayerURL(iframeURL);
			} catch (error) {
				console.error("getting last event", error);
			}
		};
		firaKey !== "" && firaKey !== undefined && startPlayer();
		console.log("recibe", firaKey, firaSrc);
	}, [firaKey, firaSrc]);

	if (!playerURL) {
		return <div></div>;
	}

	return (
		<div className={PlayerContainer}>
			<iframe
				id="myIframe"
				className={IframeWrapper}
				ref={iframeRef}
				allow="clipboard-write popups"
				src={playerURL.toString()}
			></iframe>
		</div>
	);
};

export default PlayerWrapper;
