export const getContrastColor = (hexColor: string): string => {
	// Ensure the hex color is valid and properly formatted
	if (!hexColor.includes('#')) {
		hexColor = '#'+hexColor
	}
	if (!/^#([0-9A-F]{3}){1,2}$/i.test(hexColor)) {
		throw new Error("Invalid hex color");
	}

	// Convert hex to RGB
	let r: number, g: number, b: number;
	if (hexColor.length === 4) {
		r = parseInt(hexColor[1] + hexColor[1], 16);
		g = parseInt(hexColor[2] + hexColor[2], 16);
		b = parseInt(hexColor[3] + hexColor[3], 16);
	} else {
		r = parseInt(hexColor.substr(1, 2), 16);
		g = parseInt(hexColor.substr(3, 2), 16);
		b = parseInt(hexColor.substr(5, 2), 16);
	}

	// Calculate the relative luminance
	const luminance = (channel: number) => {
		const a = channel / 255.0;
		return a <= 0.03928 ? a / 12.92 : Math.pow((a + 0.055) / 1.055, 2.4);
	};

	const relativeLuminance =
		0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);

	// Calculate contrast ratio for black and white
	const contrastBlack = (relativeLuminance + 0.05) / 0.05;
	const contrastWhite = 1.05 / (relativeLuminance + 0.05);

	// Return the color that has the higher contrast ratio
	return contrastBlack > contrastWhite ? "black" : "white";
};

function hexToRgb(hex: string): [number, number, number] {
	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, "");

	// Parse r, g, b values
	const bigint = parseInt(hex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	return [r, g, b];
}

export function getTextColor(hexColor: string): string {
	const [r, g, b] = hexToRgb(hexColor);

	// Normalize RGB values to the [0, 1] range
	const R = r / 255;
	const G = g / 255;
	const B = b / 255;

	// Calculate the perceptive luminance
	const luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B;

	// Bright colors - black font, dark colors - white font
	return luminance > 0.5 ? "#252828" : "#F7F8F9";
}

export function getRgbFromHex(hex: string, opacity: number): string {
	const [r, g, b] = hexToRgb(hex);

	const alpha: number = Math.min(Math.max(opacity, 0), 1);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
