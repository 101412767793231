import React, { useEffect } from "react";

import { FiraLogoFull } from "../../assets/FiraLogoFull";
import NoPhotoFile from "../../assets/no_photo.png";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";

import stylesheet from "./Footer.module.scss";

const {
	FooterWrapper,
	LogosWrapper,
	Separetor,
	FiraLogo,
	Logo,
	TermsText,
	RightsText,
	TextWrapper,
} = stylesheet;

interface FooterProps {
	color: string;
	contrastColor: string;
	logoURL?: string;
	fontFooter?: string;
	fontSizeFooter?: number;
	fontWeightFooter?: number;
}
const Footer: React.FC<FooterProps> = ({
	color,
	contrastColor,
	logoURL,
	fontFooter,
	fontSizeFooter,
	fontWeightFooter,
}) => {
	const currentYear = new Date().getUTCFullYear();

	useEffect(() => {
		if (fontFooter) {
			const style = document.createElement("style");
			let styleContent = `
			@import url('https://fonts.googleapis.com/css?family=${encodeURIComponent(
				fontFooter
			)}');
			.FooterContent {
			  font-family: '${fontFooter}', sans-serif;
			  font-weight: ${fontWeightFooter};
			`;

			if (fontSizeFooter && fontSizeFooter > 0) {
				styleContent += `font-size: ${fontSizeFooter}px;`;
			}

			styleContent += `}`;

			style.appendChild(document.createTextNode(styleContent));
			document.head.appendChild(style);
		}
	}, [fontFooter, fontSizeFooter, fontWeightFooter]);

	return (
		<div
			className={`${FooterWrapper} ${"FooterContent"}`}
			style={{ backgroundColor: color }}
		>
			<div className={LogosWrapper}>
				<ImageWithFallback
					className={Logo}
					src={logoURL || NoPhotoFile}
					fallbackSrc={NoPhotoFile}
					alt="Logo"
				/>
				<div style={{ borderColor: contrastColor }} className={Separetor}></div>
				<FiraLogoFull className={FiraLogo} style={{ color: contrastColor }} />
			</div>
			<div className={TextWrapper}>
				<a
					className={`${TermsText}`}
					style={{
						color: contrastColor,
					}}
				>
					Términos y condiciones
				</a>
				<span
					className={RightsText}
					style={{ color: contrastColor }}
				>{`© ${currentYear} FIRA ${process.env.REACT_APP_CURRENT_ENVIRONMENT} | All Rights Reserved.`}</span>
			</div>
		</div>
	);
};

export default Footer;
