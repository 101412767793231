import React from "react";
import stylesheet from "./BannerLive.module.scss";
import Camera from "../FiraIcons/Camera";

const { scrollingTextContainer, scrollingText, scrollingTextWrapper, icono } =
	stylesheet;

interface BannerProps {
	eventName: string;
	onClick(): void;
}
const BannerLive: React.FC<BannerProps> = ({ eventName, onClick }) => {
	return (
		<div className={scrollingTextContainer} onClick={onClick}>
			<div className={scrollingTextWrapper}>
				<div className={scrollingText}>
					<Camera className={icono} />
					{"Estamos en vivo ahora - "}
					{eventName}
				</div>
				<div className={scrollingText}>
					<Camera className={icono} />
					{"Estamos en vivo ahora - "}
					{eventName}
				</div>
			</div>
		</div>
	);
};

export default BannerLive;
