import * as React from "react";
import { SVGProps } from "react";

const Camera = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={25}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.867 9.2a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382a1 1 0 0 1 1.407.913v7.462a1 1 0 0 1-1.406.913l-3.111-1.382a2 2 0 0 1-1.983 1.738h-7.5a2 2 0 0 1-2-2v-6Z"
			fill="red"
		/>
	</svg>
);

export default Camera;
