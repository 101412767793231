import { SVGProps } from "react"
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 86 36"
    fill="none"
    focusable="true"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M3.24 2.73v5.3h8.403v2.73H3.24v6.676H.02V.02h12.703v2.71H3.24ZM15.452.02h3.24v17.416h-3.24V.02ZM34.38 17.436l-3.554-5.105c-.157.02-.373.02-.667.02h-3.927v5.065h-3.24V0h7.167c1.512 0 2.827.255 3.946.746 1.12.491 1.984 1.217 2.573 2.14s.903 2.023.903 3.299c0 1.315-.314 2.435-.962 3.377-.629.943-1.551 1.65-2.75 2.12l4.006 5.714H34.38v.04ZM33.222 3.652c-.727-.589-1.806-.903-3.2-.903h-3.79v6.93h3.79c1.413 0 2.473-.294 3.2-.902.726-.609 1.1-1.473 1.1-2.572 0-1.1-.374-1.964-1.1-2.553ZM52.071 13.41h-8.698l-1.708 4.026h-3.338L46.16.02h3.18l7.855 17.416h-3.378l-1.747-4.026Zm-1.06-2.552L47.73 3.24l-3.259 7.618h6.539ZM3.986 34.205c-1.237-.687-2.219-1.61-2.926-2.808C.353 30.2 0 28.864 0 27.392c0-1.473.353-2.808 1.06-4.006a7.325 7.325 0 0 1 2.926-2.808c1.237-.687 2.63-1.02 4.182-1.02 1.532 0 2.906.333 4.163 1.02 1.237.688 2.199 1.61 2.905 2.808.707 1.178 1.041 2.533 1.041 4.006 0 1.492-.353 2.827-1.04 4.005a7.68 7.68 0 0 1-2.906 2.808c-1.237.687-2.631 1.02-4.163 1.02-1.551 0-2.945-.333-4.182-1.02Zm7.206-1.689a5.588 5.588 0 0 0 2.12-2.1c.51-.904.786-1.905.786-3.005 0-1.1-.256-2.1-.786-3.004a5.452 5.452 0 0 0-2.12-2.1c-.903-.511-1.905-.766-3.024-.766-1.12 0-2.14.255-3.043.765a5.64 5.64 0 0 0-2.14 2.101 6.013 6.013 0 0 0-.786 3.004c0 1.1.255 2.101.785 3.004.511.904 1.237 1.59 2.14 2.101.904.51 1.925.766 3.044.766 1.12 0 2.12-.255 3.024-.766ZM32.77 19.754v15.295h-1.786l-9.19-11.407v11.407h-2.179V19.754h1.787l9.17 11.408V19.754h2.198Z"
    />
    <path
      fill="#FFDE07"
      d="M36.6 19.754h4.319v11.879h7.304v3.436H36.599V19.754ZM49.931 19.754h4.32v15.295h-4.32V19.754ZM72.825 19.754l-6.558 15.295h-4.26l-6.539-15.295h4.673l4.163 9.974 4.241-9.974h4.28ZM86 31.711v3.338H73.71V19.754h11.997v3.338h-7.717v2.572h6.794v3.24h-6.794v2.807H86Z"
    />
  </svg>
)
export { SvgComponent as FiraLogoFull }