import * as React from "react";
import { SVGProps } from "react";

const ArrowDownSimple = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={10}
		height={9}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				d="M5.14 5.906 1.484 2.25H.64l4.5 4.5 4.5-4.5h-.844L5.14 5.906Z"
				fill="#3C3C3C"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(.64)" d="M0 0h9v9H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default ArrowDownSimple;
