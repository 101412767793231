import React, { useEffect, useState } from "react";

interface ImageProps {
	src: string;
	fallbackSrc: string;
	alt?: string;
	className?: string;
}
const ImageWithFallback: React.FC<ImageProps> = ({
	src,
	fallbackSrc,
	alt,
	className,
}) => {
	useEffect(() => {
		setImgSrc(src);
	}, [src]);
	const [imgSrc, setImgSrc] = useState(src);

	const handleError = () => {
		setImgSrc(fallbackSrc);
	};

	return (
		<img
			src={imgSrc}
			onError={handleError}
			alt={alt || "Image"}
			className={className}
		/>
	);
};

export default ImageWithFallback;
