import { CartProduct, useCart } from "../../../../providers/CartContext";
import {
	useLandingContext,
} from "../../../../providers/LandingProvider";
import currencyFormat from "../../../../utils/currencyFormat";
import { getTextColor } from "../../../../utils/getContrastColor";
import CloseButtonX from "../../../FiraIcons/CloseButtonX";
import WhatsappFlat from "../../../FiraIcons/WhatsappFlat";
import WhatsappLine from "../../../FiraIcons/WhatsappLine";
import stylesheet from "./ShoppingForm.module.scss";
import React, { ChangeEvent, useContext, useState } from "react";

const {
	ShoppingFormWrapper,
	FormWrapper,
	Title,
	DisclaimerWrapper,
	TextInput,
	ShoppingButton,
	Top,
	Disabled,
	Web,
	Mobile,
} = stylesheet;

interface ShoppingFormProps {
	onClose(): void;
	store?: string;
	color: string;
	isMobile: boolean;
}

const ShoppingForm = ({
	onClose,
	store,
	color,
	isMobile,
}: ShoppingFormProps) => {
	const urlParams = new URLSearchParams(window.location.search);

	const { cart } = useCart();
	const { configuration } = useLandingContext();

	const [name, setName] = useState<string>("");
	const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(false);

	const calculateSumTotal = (products: CartProduct[]): number => {
		let sum = 0;
		for (let i = 0; i < products.length; i++) {
			const item = products[i];
			const price =
				item.price2 && parseFloat(item.price2) > 0
					? parseFloat(item.price2)
					: parseFloat(item.price1);
			sum += price * item.quantity;
		}
		return sum;
	};

	const trimmer = (inputString: string) =>
		/\s$/.test(inputString) ? inputString.trimEnd() : inputString;

	const wsMessage = `¡Hola! soy ${name},
Te envío mi lista de compras:
 ${cart
		.map(
			product =>
				`
  *${trimmer(product.name)}*
  *${trimmer(product.variationName ? product.variationName : "")}*
  *Cantidad*: ${product.quantity} / ${String(
					currencyFormat(
						product.price2 !== undefined && parseFloat(product.price2) > 0
							? parseFloat(product.price2) * product.quantity
							: parseFloat(product.price1) * product.quantity,
						configuration?.storeCurrency ? configuration.storeCurrency : ''
					)
				)}
  ¡Gracias!
`
		)
		.join("")}
  TOTAL: ${String(
		currencyFormat(
			calculateSumTotal(cart),
			configuration?.storeCurrency ? configuration?.storeCurrency : ""
		)
	)}
  -------------------------------`;

	const cleanedMessage = wsMessage
		.replace(/\n/g, "%0A")
		.replace(/:/g, "%3A")
		.replace(/ /g, "%20");

	const whatsappLink = `https://wa.me/${configuration?.storeNumber.operator}${configuration?.storeNumber.number}?text=${cleanedMessage}`;

	const openLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		const supported = document.pictureInPictureEnabled;
		if (supported) {
			const videoContainer = document.querySelector(
				"#fira_player"
			) as HTMLVideoElement;
			const pipButton = document.querySelector(
				"#videoControls .pip-button"
			) as HTMLButtonElement;

			try {
				await videoContainer.requestPictureInPicture();
			} catch (error) {
				console.error("Error entering PiP mode:", error);
			}
		}
		window.open(whatsappLink, "_blank");
	};

	return (
		<div className={`${ShoppingFormWrapper} `}>
			<div className={`${FormWrapper} ${isMobile ? Web : Mobile}`}>
				<form>
					<div className={Top}>
						<span className={Title}>{"Enviar pedido"}</span>
						<CloseButtonX
							color={"#D9D9D9"}
							style={{ cursor: "pointer" }}
							onClick={onClose}
						/>
					</div>
					<input
						type="text"
						className={TextInput}
						placeholder={"Ingresa tu nombre*"}
						onChange={e => setName(e.target.value)}
					/>

					<div className={DisclaimerWrapper}>
						<input
							type="checkbox"
							id="disclaimerAccepted"
							name="disclaimerAccepted"
							checked={disclaimerAccepted}
							onChange={() => setDisclaimerAccepted(!disclaimerAccepted)}
						/>
						<label htmlFor="disclaimerAccepted">
							<span>{"Al registrar su cuenta, acepta nuestros"}</span>{" "}
							<a href="https://marykay.com.co/Aviso-de-Privacidad" target="_blank">{"Términos y condiciones."}</a>
						</label>
					</div>
					{name === "" || !disclaimerAccepted ? (
						<button
							onClick={openLink}
							className={`${Disabled}`}
							style={{
								cursor: "pointer",
							}}
						>
							<WhatsappFlat width={24} height={24} color={"#fff"} />
							<span>{"Continuar compra"}</span>
						</button>
					) : (
						<button
							onClick={openLink}
							className={`${ShoppingButton}`}
							style={{
								backgroundColor: color,
								color: getTextColor(color),
							}}
						>
							<WhatsappFlat
								width={24}
								height={24}
								color={getTextColor(color)}
							/>
							<span>{"Continuar compra"}</span>
						</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default ShoppingForm;
